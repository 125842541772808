<template lang="html">
  <div class="">
    <div  v-if="this.open_box_lead" @click="open_box_lead_func(0)"   :style="{width: '100%',zIndex: 2,backgroundColor: 'black',opacity: 0.5,position:'fixed',marginLeft:'0%',marginTop:'0%', height: '100%' }">
    </div>

    <div  v-if="this.open_box_lead"  :style="{width: '90%', position:'fixed',marginLeft:'4%',marginTop:'0%',zIndex:10, height: '88%',background: '#FFFFFF',borderRadius: '30px' }">
      <div  :style="{height:'auto', width:'100%',fontFamily: 'Inter',fontStyle: 'normal',fontWeight: 500,fontSize: '55px'}">
          <div :style="{marginLeft:'5%', marginTop:'2%'}">
            <div :style="{height:'70px', width:'100%', display:'flex' }">
              <div :style="{fontSize:'49px', height:'10px',textAlign:'center',fontWeight:'700',marginTop:'0%', marginLeft:'40%',color:'#0b7443'}">
              Kundförfrågan
              </div>
            </div>
            <div  v-for="(lead,idx) in current_lead" :key="idx" :style="{ fontSize:'25px', marginLeft:'0%'}">
              <div class="main_comp">
                <div class="label_size">
                  Namn:
                </div>
                {{lead.name}}
              </div>
              <div class="main_comp">
                <div class="label_size">
                  Kontaktinfo:
                </div>
                {{lead.contact_info}}
              </div>
            </div>
            <div class="box_description">
              <div class="label_size">
                Beskrivning:
              </div>
              <textarea :style="{marginLeft:'2%', whiteSpace: 'preWrap', resize: 'none',width:'100%',height:'100%'}" name="name" rows="8" cols="80" v-model="description">
              </textarea>
            </div>
            <div class="boxes">
              <div class="label_size">
                Bilder:
              </div>
              <div class="box_flow_images" data-toggle="tooltip" data-placement="top" title="Klicka på för att förstora"  v-for="(image,idxx ) in image_list" :key="idxx">
                <img :src="get_image(company_id,image.image)" @click="open_enlarge_image_box(image.image)" class="show_chosen_image_first"  alt="image" >
              </div>
            </div>
        </div>
      </div>
    </div>

    <div class="posts"  v-for="(lead,idx) in general_leads_list " :key="idx">
      <div class="postheader" >
        <div class="post_namn"  >
          Created: {{lead.created}}
        </div>
      </div>
      <div class="post" >
        <div   class="imagepostclass"  @click="open_box_lead_func(lead.id)">
             <img class="imagepostimage" :src="get_image(0,lead.lead_image)"  />
        </div>
        <div class="">
          {{lead.description}}
        </div>
     </div>
    </div>
  </div>
</template>

<script>
// import router from '../router/index'
import axios from 'axios';
import {API_URL} from '../../config.js'


export default {
  data:function(){
    return{
      general_leads_list:[],
      shown_image_flag:false,
      shown_image:'',
      open_box_lead:false,
      image_list:[],
      description:'',
      current_lead:[]

    }
},
mounted(){
  this.get_general_leads()


},
methods:{

  get_image(id,image){
    if (image){
      var data = 'enjordrepair_user' + 0 + "/" + image
      return this.$func.get_image(data)
    }
  },
  get_general_leads(){
    var self = this
    axios({
        method:'get',
        url: API_URL+ '/get_general_leads',
      })
      .then(function(response){
        self.general_leads_list = response.data
      })
  },
  open_enlarge_image_box(image){
    this.shown_image_flag = true
    this.shown_image=image
  },

  connect_lead(company_id){
    var self = this
    axios({
        method:'post',
        url: API_URL+ '/connect_lead',
        data:{lead_id:this.connected_lead_id,company_id:company_id}
      })
      .then(function(){
        self.$alert("lead connected")
     })
  },
  open_box_lead_func(lead_id){
    if(this.open_box_lead){
      this.open_box_lead = false
    }else{
      this.open_box_lead = true
      this.get_lead(lead_id)
    }
  },
  get_lead(lead_id){
    var self = this
    axios({
       method:'get',
       url: API_URL+ '/get_lead'+'?lead_id='+lead_id,
     })
     .then(function(response){
       self.current_lead = response.data["show_lead_list"]
       self.description = response.data["show_lead_list"][0]["description"]
       self.image_list=response.data["image_list"]
     })
  }


}

}
</script>

<style lang="css" scoped>
.posts{
  height: 100%;
  width: 100%;
  margin-left: 0%;
  display: flex;
  margin-top: 2%;
  background-color: white;
  flex-direction: column;
}
.post_namn{
  display: flex;
  height: 37px;
  width: 139px;
  margin-left: 13%;
  position: absolute;
  margin-top: 0%;
}
.postheader{
  display: flex;
  padding-right: 5px;
  height: 52px;
  width: 90%;
  margin-top: 5%;
  margin-left: 32px;

}

.imagepostclass{
  margin-top: 0%;
  display: flex;
  width: 80%;
  margin-left: 0%;
  height: 286px;
  border-radius: 17px;
}

.imagepostimage{
  object-fit: cover;
  margin-top: 0%;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 17px;
  /* position: absolute; */
  z-index: 1;
  cursor: pointer;
}

.post{
  width: 91.5%;
  display: flex;
  height: 80%;
  max-height: 370px;
  margin-top: 0%;
  margin-left: 4.2%;
}

.main_comp{
  display: flex;
  width: auto;
    margin-left: 0%;
    margin-top: 1%;
}
.box_description{
  height:210px;
  padding-bottom: 0.2%;
  display:flex;
  margin-top: 1%;
  width: 80%;
  font-size: 25px;
}

.label_size{
 width:150px;
 font-weight: 600
}
.boxes{
  /* height:30px;
  margin-left: 5%;
   display:flex;
   overflow-x: auto;
   scrollbar-width:thin; */
  font-size: 25px;
  text-align: left;
   height:20px;
   padding-bottom: 0.2%;
   margin-top: 2%;
   /* margin-left: 2%; */
display: flex;
    margin-top: 1%;

    width: 90%;

}
.box_flow_images{
  margin-left: 1%;
  color: grey;
  height: 90px;
  width: 98px;
  /* overflow-x: auto; */
/* width: auto; */
display: flex;
}


.show_chosen_image_first{
 height: 100%;
    border-radius: 8px;
  width: 100%;
  object-fit: contain;
  background-color: white;
cursor: pointer;
}

</style>
